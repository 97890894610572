import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Node-RED HTTP/2 Webproxy",
  "path": "/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_NGINX_Proxy/",
  "dateChanged": "2022-03-29",
  "author": "Mike Polinowski",
  "excerpt": "Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Node-RED HTTP/2 Webproxy' dateChanged='2022-03-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_mit_INSTAR_Cloud_Webhook/Node-RED_NGINX_Proxy/' locationFR='/fr/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_NGINX_Proxy/' crumbLabel="Proxy" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "node-red-with-the-instar-cloud-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-with-the-instar-cloud-webhook",
        "aria-label": "node red with the instar cloud webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED with the INSTAR Cloud Webhook`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-http2-webproxy"
        }}>{`Node-RED HTTP/2 Webproxy`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configuring-nginx"
            }}>{`Configuring NGINX`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#starting-nginx"
            }}>{`Starting NGINX`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-the-instar-cloud"
        }}>{`Configuring the INSTAR Cloud`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#next-steps"
        }}>{`Next Steps`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "node-red-http2-webproxy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-http2-webproxy",
        "aria-label": "node red http2 webproxy permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED HTTP/2 Webproxy`}</h2>
    <p>{`I already secured Node-RED with a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_Docker_Installation/#adding-admin-and-webhook-security"
      }}>{`User Login`}</a>{` but I want to add HTTPS TLS encryption on top. For this I am going to setup an NGINX Ingress for my Docker Cluster using the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/_/nginx"
      }}>{`latest NGINX Docker image`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull nginx:1.21.6-alpine`}</code></pre></div>
    <h3 {...{
      "id": "configuring-nginx",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuring-nginx",
        "aria-label": "configuring nginx permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring NGINX`}</h3>
    <p>{`You can use this `}<a parentName="p" {...{
        "href": "https://github.com/mpolinowski/nginx_docker_ingress"
      }}>{`Github Repository`}</a>{` to get started with the NGINX configuration. Use Git to clone the repository or simply download the ZIP file from Github and unzip it into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt`}</code>{` directory of your server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` clone https://github.com/mpolinowski/nginx_docker_ingress.git /opt/nginx_docker_ingress`}</code></pre></div>
    <p>{`This repository already brings everything we need. We only need to edit the default server configuration file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /opt/nginx_docker_ingress/conf.d/default.conf`}</code></pre></div>
    <p>{`Delete it's content and replace it with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    listen `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`;
    listen `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`;

    server_name my.server.address;

    return `}<span parentName="code" {...{
            "className": "token number"
          }}>{`301`}</span>{` https`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`//$server_name$request_uri;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`SQL-Logging
    listen `}<span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span>{` ssl http2 default_server;
    listen `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span>{` ssl;
    # ssl_certificate /opt/letsencrypt/live/my.domain.com/fullchain.pem;
    # ssl_certificate_key /opt/letsencrypt/live/my.domain.com/privkey.pem;
    include ssl/self-signed.conf; # Replace with the `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` lines above when using CA Cert
    include ssl/ssl-params.conf;
    include /etc/nginx/conf.d/header.conf;

    server_name my.server.address;


    location / `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      proxy_pass http`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`//nodered:1880/;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


    error_page  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`404`}</span>{` /`}<span parentName="code" {...{
            "className": "token number"
          }}>{`404`}</span>{`.html;
    error_page  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`502`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`503`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`504`}</span>{` /50x.html;
    location = /50x.html `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    root   /usr/share/nginx/html;
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`There are a two changes that you will have to make here:`}</p>
    <ol>
      <li parentName="ol">{`Change `}<strong parentName="li">{`both`}</strong>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`my.server.address`}</code>{` with your servers domain name or IP address.`}</li>
      <li parentName="ol">{`The repository comes with a `}<strong parentName="li">{`Self-signed Certificate`}</strong>{` that is included from `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ssl/self-signed.conf`}</code>{`. You can use this certificate to get started. `}<strong parentName="li">{`It will work with the INSTAR Cloud Webhook function`}</strong>{`. But, you will either want to replace it with your own self-signed certificate or use `}<strong parentName="li">{`Let's Encrypt`}</strong>{` to get a proper certificate and include it instead of the self-signed certificate.`}</li>
    </ol>
    <h3 {...{
      "id": "starting-nginx",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#starting-nginx",
        "aria-label": "starting nginx permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starting NGINX`}</h3>
    <p>{`We now have to start this container with the following flags:`}</p>
    <ul>
      <li parentName="ul">{`open ports `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`443`}</code>{` (HTTPS) and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`80`}</code>{` (HTTP)`}</li>
      <li parentName="ul">{`a volume mount for `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/opt/nginx_docker_ingress`}</code></li>
      <li parentName="ul">{`the container must be on the same virtual network as Node-RED, e.g. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`smart`}</code></li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`443`}</span>{`:443 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`:80 -v /opt/nginx_docker_ingress:/etc/nginx --network`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`smart --name ingress nginx:1.21.6-alpine`}</code></pre></div>
    <p>{`Verify that the Node-RED user interface is now accessible via HTTPS:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`https://my.server.address:443/`}</code></pre></div>
    <p>{`When you are using a self-signed certificate you will see a warning that the certificate is self-signed - accept to continue:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "912px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/8b69f/Node-RED_INSTAR_Cloud_Webhook_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABEElEQVQY04WQOUsDURSF8w90lBlnefftazLzZokziQtiJ1goBAsrIWAZIY11ivxxeTHCoIXwcTjNd+GeSZqx85ihghc5+xdU8DGT2uqnuxKBVsIxajmzUkw5s4KHfiiOcyfFlBKVZ3R8ZXLfu81LR6iz1mtdaVWaUYaiKiVn1njBHSWaUcOoOcpZxuOEzpxv674JDG0ztO2iqYfaDyHbpW+Xvlk03VU3vzHGK1ViJIIMiGPEgTtQFRCNkQCQOCAwSAKSnqUkuiBR8p1FjJIEspQE+fg9MQVzCMlfk6CcRu+fp9t9tNlFH7uT7R7fPsyry6rsCagfuWDoz5gHmSWPr/FqHT+/BVZraK5L443xGOQXNls+PrwAZJMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c0895a433a4fe3eb645cab00acc5ae0/e4706/Node-RED_INSTAR_Cloud_Webhook_01.avif 230w", "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/d1af7/Node-RED_INSTAR_Cloud_Webhook_01.avif 460w", "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/75dc0/Node-RED_INSTAR_Cloud_Webhook_01.avif 912w"],
              "sizes": "(max-width: 912px) 100vw, 912px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c0895a433a4fe3eb645cab00acc5ae0/a0b58/Node-RED_INSTAR_Cloud_Webhook_01.webp 230w", "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/bc10c/Node-RED_INSTAR_Cloud_Webhook_01.webp 460w", "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/b116a/Node-RED_INSTAR_Cloud_Webhook_01.webp 912w"],
              "sizes": "(max-width: 912px) 100vw, 912px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c0895a433a4fe3eb645cab00acc5ae0/81c8e/Node-RED_INSTAR_Cloud_Webhook_01.png 230w", "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/08a84/Node-RED_INSTAR_Cloud_Webhook_01.png 460w", "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/8b69f/Node-RED_INSTAR_Cloud_Webhook_01.png 912w"],
              "sizes": "(max-width: 912px) 100vw, 912px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c0895a433a4fe3eb645cab00acc5ae0/8b69f/Node-RED_INSTAR_Cloud_Webhook_01.png",
              "alt": "Node-RED with the INSTAR Cloud Webhook",
              "title": "Node-RED with the INSTAR Cloud Webhook",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Are you able to access Node-RED through your NIGNX ingress? If yes, continue with re-configuring your INSTAR Cloud account.`}</p>
    <h2 {...{
      "id": "configuring-the-instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-the-instar-cloud",
        "aria-label": "configuring the instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring the INSTAR Cloud`}</h2>
    <p>{`All that needs to be changed here is the port - it is now `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`443`}</code>{` instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1880`}</code>{`. And you have to set the protocol to secure - that's it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/212c5e816774fc2c9b453a2c88ae2598/f53a0/Node-RED_INSTAR_Cloud_Webhook_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABb0lEQVQoz2WSS27cQAxEddrcINnlHDlNNjYQb7IJsg3GMCCNPFJLTTb7w/6Q7QiDGQySwltwU6giwWEcR2shhACARLQsCzOvxuzGQEhzKLPPeyrv87xtm7UgoncN67qM44SIzjnvfc7sPWXmrtJV+12qXUVF+oOG+Ty+nf5c5ik6TJ58kTUUy+Jq32J9x3CheHHxGFyCrFgObFbRPnz7hV+et89P5tP39euLtTGDj+BTapqqeC6U8u68ixxy5apXYjnqD79n9/wKP97s0+v+c3I+BLMumzGttWs3aQ0Acikpc5PDI6rtCO5DCVQ8FI+FoEbKmYmIOett4SpilyUZw9Y2IvH+ALGLDI5ot2ABdwvOUUoJEeXhME11H8d0OuXzWS+XK3I+91oHIgIARAQAIqq1MvOjWUSj9+wcEwnzna76rzkzA4C1NoRwbd5UYZqOqH1XYw62rU2TlvK/OSOCu0lVRQSsbTEKs95oKf19kg8cLTtCQaREsQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/212c5e816774fc2c9b453a2c88ae2598/e4706/Node-RED_INSTAR_Cloud_Webhook_02.avif 230w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/d1af7/Node-RED_INSTAR_Cloud_Webhook_02.avif 460w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/7f308/Node-RED_INSTAR_Cloud_Webhook_02.avif 920w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/23a38/Node-RED_INSTAR_Cloud_Webhook_02.avif 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/212c5e816774fc2c9b453a2c88ae2598/a0b58/Node-RED_INSTAR_Cloud_Webhook_02.webp 230w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/bc10c/Node-RED_INSTAR_Cloud_Webhook_02.webp 460w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/966d8/Node-RED_INSTAR_Cloud_Webhook_02.webp 920w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/dac18/Node-RED_INSTAR_Cloud_Webhook_02.webp 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/212c5e816774fc2c9b453a2c88ae2598/81c8e/Node-RED_INSTAR_Cloud_Webhook_02.png 230w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/08a84/Node-RED_INSTAR_Cloud_Webhook_02.png 460w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/c0255/Node-RED_INSTAR_Cloud_Webhook_02.png 920w", "/en/static/212c5e816774fc2c9b453a2c88ae2598/f53a0/Node-RED_INSTAR_Cloud_Webhook_02.png 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/212c5e816774fc2c9b453a2c88ae2598/c0255/Node-RED_INSTAR_Cloud_Webhook_02.png",
              "alt": "Node-RED with the INSTAR Cloud Webhook",
              "title": "Node-RED with the INSTAR Cloud Webhook",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/Webhook/"
        }}>{`The INSTAR Cloud Webhook`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_Docker_Installation"
            }}>{`Installing Node-RED v2 with Docker`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_GET_Webhook"
            }}>{`Node-RED GET Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_POST_Webhook"
            }}>{`Node-RED POST Webhook`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      